import React from "react";

const LoadingContext = React.createContext(null);

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default function useLoading() {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw Error("Deve estar dedntro de um Loading Provider");
  }

  return context;
}
