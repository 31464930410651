import React from "react";

import "./styles.scss";

function OptionBox({ children, type, active, setActiveType }) {
  return (
    <div
      onClick={() => setActiveType(type)}
      className={`option-box option-box-${type} ${
        active === type ? "option-box-active" : ""
      } d-flex align-items-center justify-content-center`}
    >
      {children}
    </div>
  );
}

export default OptionBox;
