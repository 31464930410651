import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import api from "config/api";
import errorRequest from "common/errorRequest";
import Loading from "components/Loading";

import OptionBox from "components/OptionBox";
import TypeContent from "./TypeContent";

import { ValueContext } from "views/admin/Home/index";

function TypeSelector({ activeType, setActiveType }) {
  const history = useHistory();
  const [nuvemProduct, setNuvemProduct] = useContext(ValueContext);
  const [loading, setLoading] = useState(false);
  const [activeTypeName, setActiveTypeName] = useState("");
  const [fieldsValues, setFieldsValues] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const [inputFields, setInputFields] = useState();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.app.get(`/type`);
        let types = data;
        types.sort(function (a, b) {
          return a.id - b.id;
        });
        setProductTypes(types);
      } catch (error) {
        errorRequest(history, error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    if (activeType > 0 && productTypes.length) {
      setNuvemProduct(false);
      let activeFields = productTypes[activeType - 1].fields;
      setInputFields(activeFields.split(", "));
    }
    if (activeType === 1) {
      setNuvemProduct(false);
      setActiveTypeName("implantes");
      setFieldsValues({});
    }
    if (activeType === 2) {
      setNuvemProduct(false);
      setActiveTypeName("transferentes");
      setFieldsValues({});
    }
    if (activeType === 3) {
      setNuvemProduct(false);
      setActiveTypeName("cicatrizadores");
      setFieldsValues({});
    }
    if (activeType === 4) {
      setNuvemProduct(false);
      setActiveTypeName("pilares");
      setFieldsValues({});
    }
    setLoading(false);
  }, [activeType, productTypes, setNuvemProduct]);

  return (
    <>
      {loading && <Loading />}
      <div className="type-selector d-flex flex-column flex-md-row justify-content-between">
        <OptionBox type={1} active={activeType} setActiveType={setActiveType}>
          Implantes
        </OptionBox>
        <OptionBox type={2} active={activeType} setActiveType={setActiveType}>
          Transferentes
        </OptionBox>
        <OptionBox type={3} active={activeType} setActiveType={setActiveType}>
          Cicatrizadores
        </OptionBox>
        <OptionBox type={4} active={activeType} setActiveType={setActiveType}>
          Pilares
        </OptionBox>
      </div>
      <div>
        {activeType === 0 && (
          <div className="start-text">
            <p>
              <span>Escolha acima</span> o tipo de produto que você deseja para
              criar novas variações personalizadas.
            </p>
            <p>Cada tipo conta com variações específicas para o seu produto</p>
          </div>
        )}
        {activeType !== 0 && (
          <TypeContent
            type={activeTypeName}
            fields={inputFields}
            fieldsValues={fieldsValues}
            setFieldsValues={setFieldsValues}
            activeType={activeType}
          />
        )}
      </div>
    </>
  );
}

export default TypeSelector;
