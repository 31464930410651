import React, { useState, createContext } from "react";

import "./styles.scss";

import Page from "components/Page";
import TypeSelector from "./TypeSelector";
import ItemList from "./ItemList";

export const ValueContext = createContext(null);

export default function Home() {
  const [productChoose, setProductChoose] = useState();
  const [activeType, setActiveType] = useState(0);

  return (
    <ValueContext.Provider value={[productChoose, setProductChoose]}>
      <Page title="Bem-vindo ao aplicativo Variações Personalizadas">
        <div className="row">
          <div className="col-12 col-lg-7">
            <TypeSelector
              activeType={activeType}
              setActiveType={setActiveType}
            />
          </div>
          <div className="col-12 col-lg-5">
            <div className="d-flex flex-column">
              <ItemList
                name="Lista de Produtos Alterados"
                type="produtos"
                activeType={activeType}
              />
              <ItemList
                name="Lista de Tags Adicionadas"
                type="tags"
                activeType={activeType}
              />
            </div>
          </div>
        </div>
      </Page>
    </ValueContext.Provider>
  );
}
