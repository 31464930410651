import React, { useState } from "react";
import NumberFormat from "react-number-format";
import maskPhone from "common/maskPhone";

import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";

import "./styles.scss";

export default function Support() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [details, setDetails] = useState("");

  async function sendEmailToSupport(event) {
    event.preventDefault();

    if (!name || !storeName || !email || !details) {
      Swal.fire(
        "Atenção",
        "Preencha todos os campos e tente novamente!",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente enviar isso para o suporte?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.authenticate.post(`/support`, {
            name,
            storeName,
            email,
            cellphone,
            details,
          });

          Swal.fire(
            "Sucesso!",
            "As informações foram enviadas para o suporte",
            "success"
          );
          setName("");
          setStoreName("");
          setCellphone("");
          setEmail("");
          setDetails("");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page title="Bem-vindo(a) ao suporte EVA Commerce" loading={loading}>
      <Col>
        <Row className="mt-3 col-10 p-0">
          <p>Bem vindo ao suporte Eva Commerce.</p>
          <p>
            Caso tenha alguma dúvida ou precise conversar com um de nossos
            atendentes,
            <br /> por favor utilize os meios de contato listados ou nos envie
            sua dúvida preenchendo o formulário abaixo.
          </p>
        </Row>
        <Row>
          <Col className="col-6 p-0">
            <Form>
              <FormGroup className="mb-1 col-10 p-0">
                <Label className="form-label" for="supportStoreName">
                  Informe o nome de sua loja *
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="supportStoreName"
                    className="form-input"
                    type="text"
                    placeholder="Nome da loja"
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-1 col-10 p-0">
                <Label className="form-label" for="supportName">
                  Informe seu nome *
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="supportName"
                    className="form-input"
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
                <Label className="form-label" for="supportEmail">
                  Informe seu email para contato *
                </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-envelope" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-input"
                    type="email"
                    id="supportEmail"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <Label className="form-label" for="supportTel">
                  Informe seu telefone para contato
                </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <NumberFormat
                    className="form-input form-control"
                    type="text"
                    id="supportTel"
                    placeholder="Telefone"
                    format={maskPhone}
                    customInput={Input}
                    value={cellphone}
                    onChange={(e) => setCellphone(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-1 col-10 p-0">
                <Label className="form-label" for="inputDescricao">
                  Informe sua dúvida e nos conte em que podemos ajudar *
                </Label>
                <InputGroup>
                  <textarea
                    id="inputDescricao"
                    className="form-input form-textarea"
                    type="text"
                    rows="2"
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                  ></textarea>
                </InputGroup>
              </FormGroup>
              <FormGroup className="d-flex justify-content-end col-10 mt-3 p-0">
                <Button
                  className="mb-3"
                  color="primary"
                  onClick={(e) => sendEmailToSupport(e)}
                >
                  Enviar mensagem
                </Button>
              </FormGroup>
            </Form>
          </Col>
          <Col className="col-5 p-0">
            <Row className="pt-4">
              <a
                className="support-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5516993263630&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Estou%20utilizando%20um%20sistema%20EVA%20e%20preciso%20de%20suporte.%20Pode%20me%20ajudar%3F"
              >
                <i className="fas fa-globe" />{" "}
                <a
                  href="https://evacommerce.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-muted"
                >
                  evacommerce.com.br
                </a>
              </a>
            </Row>
            <Row className="pt-3">
              <a
                className="support-link"
                rel="noopener noreferrer"
                href="mailto:contato@evacommerce.com.br?subject=%5BSuporte%20EVA%5D%20Gostaria%20de%20tirar%20uma%20d%C3%BAvida"
              >
                <i className="far fa-envelope" />{" "}
                <span className="text-muted">contato@evacommerce.com.br</span>
              </a>
            </Row>
          </Col>
        </Row>
      </Col>
    </Page>
  );
}
