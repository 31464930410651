import React, { useContext } from "react";

import "./styles.scss";

import { ValueContext } from "views/admin/Home/index";

function ProductSample({ setModalProduct, modalProduct }) {
  const [nuvemProduct, setNuvemProduct] = useContext(ValueContext);

  return (
    <div
      onClick={() => setModalProduct(!modalProduct)}
      className="product-sample d-flex align-items-center justify-content-center"
    >
      <div className="product-sample-content">
        {nuvemProduct ? (
          <>
            <img
              src={nuvemProduct.urlProduct}
              alt={`Imagem de ${nuvemProduct.name}`}
            />
            <span>{nuvemProduct.name}</span>
          </>
        ) : (
          <>
            <img
              src={require("../../assets/img/productsample.png")}
              alt="Imagem de exemplo para escolha de produto"
            />
            <span className="mt-2">
              Selecione
              <br />
              um produto
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default ProductSample;
