/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardImg,
  Row,
} from "reactstrap";

import Loading from 'components/Loading';
import api from 'config/api';
import logo from "../../../assets/img/logo_dark_crm.png";
import validatePassword from '../../../common/validatePassword';

import "./styles.scss";

export default function CreatePassword() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [strong, setStrong] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  // Verifica a 'força' da senha
  useEffect(() => {
    const { strong, error } = validatePassword(password);
    setStrong(strong);
    setPasswordError(error);
  }, [password]);

  async function submit(e) {
    e.preventDefault();
    let errors = [];

    if (passwordError) return;

    if (!email) errors.push('Preencha o campo de e-mail');

    if (!confirmPassword) {
      errors.push('Confirme sua senha');
    } else {
      if (password !== confirmPassword) {
        errors.push('As senhas não coincidem');
      }
    }
    setError(errors);

    if (errors.length === 0) {
      setLoading(true);
      setError([]);
      try {
        await api.authenticate.post('create-password', {
          email,
          password,
        });
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: 'Cadastro realizado com sucesso!'
        }).then(() => {
          history.push('/');
        });
      } catch (error) {
        Swal.fire('Erro', error.response.data.message, 'error');
      }
      setLoading(false);
    }
  }

  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-transparent p-5"
          >
            <CardImg src={logo} alt="logo" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Escolha sua senha</small>
            </div>
            <Form role="form" onSubmit={submit}>
              <Row>
                <Col>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-mail"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form className="mb-3">
                <Col>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Senha"
                        type="password"
                        maxLength="24"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirmar Senha"
                        type="password"
                        maxLength="24"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="text-muted font-italic">
                <Col tag='small' sm='12' className='mb-2'>
                  Segurança da senha:{' '}
                  {!strong ? (
                    <span className="text-danger font-weight-700">fraca</span>
                  ) : (
                    <span className="text-success font-weight-700">forte</span>
                  )}
                </Col>
                {passwordError && (
                  <Col tag='small' className="error-info">{passwordError}</Col>
                )}
                {error &&
                  error.map((err, i) => (
                    <Col tag='small' key={i} className="error-info w-100">
                      {err}
                      <br></br>
                    </Col>
                  ))}
              </Row>
              <div className="text-center">
                <Button
                  onClick={(e) => submit(e)}
                  className="my-4"
                  color="primary"
                  type="submit"
                >
                  Salvar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
