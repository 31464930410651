import React, { useState, useContext } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import api from "config/api";
import errorRequest from "common/errorRequest";
import Loading from "components/Loading";

import Swal from "sweetalert2";

import ProductSample from "components/ProductSample";
import ProductModal from "components/ProductModal";

import { ValueContext } from "../../index";

function TypeContent({
  type,
  fields,
  fieldsValues,
  setFieldsValues,
  activeType,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalProduct, setModalProduct] = useState(false);
  const [nuvemProduct, setNuvemProduct] = useContext(ValueContext);

  function createTag(value, inputName) {
    setFieldsValues((fieldsValues) => ({
      ...fieldsValues,
      [inputName]: value,
    }));
  }

  const noImage = require("assets/img/brand/no-image.png");

  async function createVariation(e) {
    e.preventDefault();
    setLoading(true);
    let tags = objToString(fieldsValues, "tag");
    let tagString = "VP$" + tags;
    let tagName = objToString(fieldsValues, "name");
    let productName = nuvemProduct.name;
    let productId = nuvemProduct.nuvemShopIdProduct;
    let productTag = nuvemProduct.tags + ", " + tagString;

    try {
      await api.app.post(`/tag`, {
        name: tagString,
        display_name: tagName,
        prod_id: productId,
        prod_name: productName,
        type_id: activeType,
        tags: productTag,
      });
      getProduct(e, productId);
      Swal.fire("Sucesso!", "Sua variação foi criada com sucesso!", "success");
    } catch (error) {
      errorRequest(history, error);
      Swal.fire(
        "Ops!",
        "Não conseguimos criar sua variação. Tente novamente mais tarde ou entre em contato com o suporte",
        "warning"
      );
    }
    setLoading(false);
  }

  async function getProduct(e, id) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await api.app.get(`/nuvem/product/${id}`);
      let product = data;

      if (product.name) {
        const newProduct = {
          name: product.name.pt,
          tags: product.tags,
          urlProduct:
            product.images && product.images.length
              ? product.images[0].src
              : noImage,
          nuvemShopIdProduct: product.id,
          price:
            product.variants && product.variants.length > 0
              ? product.variants[0].promotional_price
                ? product.variants[0].promotional_price
                : product.variants[0].price
              : 0,
        };
        setFieldsValues((fieldsValues) =>
          Object.fromEntries(Object.keys(fieldsValues).map((key) => [key, ""]))
        );
        setNuvemProduct(newProduct);
      } else {
        setNuvemProduct(false);
      }
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function objToString(obj, type) {
    return Object.entries(obj).reduce((actual, [name, value], index) => {
      if (type === "tag") {
        if (actual.length) {
          return `${actual}-${fields[
            index
          ].toLowerCase()}:${value.toLowerCase()}`;
        } else {
          return `${fields[index].toLowerCase()}:${value.toLowerCase()}`;
        }
      } else {
        if (actual.length) {
          return `${actual} ${capitalizeFirstLetter(value)}`;
        } else {
          return `${capitalizeFirstLetter(value)}`;
        }
      }
    }, "");
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {loading && <Loading />}
      <ProductModal
        isOpen={modalProduct}
        toggle={() => setModalProduct(!modalProduct)}
        type={activeType}
      />
      <div className="d-flex">
        <div className="col-12 col-lg-7">
          <form onSubmit={(e) => createVariation(e)}>
            {fields &&
              fields.length &&
              fields.map((field, index) => (
                <div
                  className="form-group"
                  key={`${type}-${field}-${index + 1}`}
                >
                  <label
                    htmlFor={`${type}${index + 1}`}
                    className="label-type-content"
                  >
                    {field}
                  </label>
                  <input
                    type="text"
                    className="form-control input-type-content"
                    value={fieldsValues[`${type}${index + 1}`]}
                    id={`${type}${index + 1}`}
                    placeholder="Digite aqui..."
                    required
                    disabled={nuvemProduct ? false : true}
                    onChange={(e) =>
                      createTag(e.target.value, `${type}${index + 1}`)
                    }
                  />
                </div>
              ))}
            <button type="submit" className="btn button-eva">
              Criar variação
            </button>
          </form>
        </div>
        <div className="col-12 col-lg-5 pr-0">
          {nuvemProduct ? (
            <ProductSample
              modalProduct={modalProduct}
              setModalProduct={setModalProduct}
              nuvemProduct={nuvemProduct}
            />
          ) : (
            <ProductSample
              modalProduct={modalProduct}
              setModalProduct={setModalProduct}
              nuvemProduct={false}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TypeContent;
