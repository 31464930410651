import React, { useState, useContext } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import formatCurrency from "common/formatCurrency";

import { ValueContext } from "views/admin/Home/index";

import api from "config/api";
import errorRequest from "common/errorRequest";
import Loading from "components/Loading";

import Swal from "sweetalert2";

const noImage = require("assets/img/brand/no-image.png");

export default function TableLineProduct({
  product,
  setProduct,
  type,
  toggle,
}) {
  const history = useHistory();
  const [nuvemProduct, setNuvemProduct] = useContext(ValueContext);
  const [loading, setLoading] = useState(false);

  async function addProduct() {
    setLoading(true);

    try {
      const { data } = await api.app.get(`/product/${product.id}`);
      if (data && type !== data.type_id) {
        Swal.fire(
          "Ops!",
          "Este produto já está inserido em outra categoria. Remova-o antes de adicionar em uma nova.",
          "warning"
        );
        setNuvemProduct(false);
      } else {
        const newProduct = {
          name: product.name.pt,
          tags: product.tags,
          urlProduct:
            product.images && product.images.length
              ? product.images[0].src
              : noImage,
          nuvemShopIdProduct: product.id,
          price:
            product.variants && product.variants.length > 0
              ? product.variants[0].promotional_price
                ? product.variants[0].promotional_price
                : product.variants[0].price
              : 0,
        };
        setNuvemProduct(newProduct);
        toggle();
      }
    } catch (error) {
      errorRequest(history, error);
    }

    setLoading(false);
  }

  return (
    <>
      {loading && <Loading />}
      <tr>
        <td className="TableTd">
          <div className="singleorder-table-imgwrapper">
            <img
              className="singleorder-table-img"
              src={
                product.images && product.images.length
                  ? product.images[0].src
                  : noImage
              }
              alt="Imagem do produto"
            />
          </div>
        </td>
        <td className="TableTd text-left">{product.name.pt}</td>
        <td className="TableTd">
          {formatCurrency(
            product.variants[0].promotional_price
              ? product.variants[0].promotional_price
              : product.variants[0].price
          )}
        </td>
        <td className="TableTd">
          <div className="table-action">
            <div
              className="table-action-button-edit"
              onClick={() => window.open(product.canonical_url)}
            >
              <i className="fas fa-eye" />
            </div>
            <div
              className="table-action-button-sucess"
              onClick={() => addProduct()}
            >
              <i className="fas fa-arrow-right" />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}
