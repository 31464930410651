import React from "react";
import { useHistory } from "react-router-dom";

import Loading from "components/Loading";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

import Header from "components/Header";

export default function Page({
  children,
  title,
  loading,
  back,
  topButtonLink,
  topButtonEva,
  border,
}) {
  const history = useHistory();

  const childrenHeader = children && children.length > 1 ? children[0] : "";
  const childrenBody = children && children.length > 1 ? children[1] : children;

  return (
    <>
      <Header />
      {loading && <Loading />}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                {(title || back || topButtonLink || topButtonEva) && (
                  <div className="d-flex">
                    <Col className={`col-6 d-flex align-items-center`}>
                      {title && !back && (
                        <Row>
                          <h4 className="m-0 page-text">{title}</h4>
                        </Row>
                      )}
                      {back && (
                        <Row>
                          <button
                            className="button-like-link"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-arrow-left" /> Voltar
                          </button>
                        </Row>
                      )}
                    </Col>
                    {(topButtonLink || topButtonEva) && (
                      <Col className="col-6 d-flex align-items-center justify-content-end">
                        {topButtonLink && (
                          <Button
                            color="link"
                            className="text-eva"
                            onClick={() =>
                              history.push(`/admin/painel-de-vendas`)
                            }
                          >
                            Botão em link
                          </Button>
                        )}
                        {topButtonEva && (
                          <Button
                            className="button-eva"
                            onClick={() => history.push(`/admin/home`)}
                          >
                            Botão EVA
                          </Button>
                        )}
                      </Col>
                    )}
                  </div>
                )}
                {childrenHeader}
              </CardHeader>
              <CardBody className={`${border}`}>{childrenBody}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
