import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import errorRequest from "common/errorRequest";
import Loading from "components/Loading";

import {
  Modal,
  Label,
  Input,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";

import api from "config/api";
import TableLineProduct from "./TableLineProduct";

export default function ProductModal({ isOpen, toggle, type }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [productsNuvemShop, setProductsNuvemShop] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    setPage(1);
    setProductsNuvemShop([]);
  }, [search]);

  async function fetchProducts() {
    setLoading(true);
    try {
      if (search) {
        const { data } = await api.app.get(
          `/nuvem/product?q=${search}&page=${page}&per_page=${limit}`
        );
        setProductsNuvemShop(data[0]);
        setLoading(false);
      } else {
        const { data } = await api.app.get(
          `/nuvem/product?page=${page}&per_page=${limit}`
        );
        setProductsNuvemShop(data[0]);
        setLoading(false);
      }
    } catch (error) {
      errorRequest(history, error);
      setLoading(false);
    }
    setLoading(false);
  }

  return (
    <>
      {loading && <Loading />}
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Escolher produto para criar variação</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <FormGroup>
                <Label className="form-label" for="inputProduct">
                  Escolha um produto
                </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-box-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    id="inputProduct"
                    className="table-search-input"
                    placeholder="Digite o nome do produto aqui..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        fetchProducts(false);
                      }
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      className="table-search-button btn"
                      onClick={() => fetchProducts(false)}
                    >
                      Buscar
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                className="d-none d-md-table text-center"
                responsive
                striped
              >
                <thead>
                  <tr>
                    <th className="TableTh">Imagem</th>
                    <th className="TableTh text-left">Produto</th>
                    <th className="TableTh">Preço</th>
                    <th className="TableTh">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {productsNuvemShop && productsNuvemShop.length > 0 ? (
                    productsNuvemShop.map((product, index) => {
                      if (productsNuvemShop.length === index + 1)
                        return (
                          <TableLineProduct
                            key={product.id + "-product-modal"}
                            product={product}
                            type={type}
                            toggle={() => toggle()}
                          />
                        );
                      else
                        return (
                          <TableLineProduct
                            key={product.id + "-product-modal"}
                            product={product}
                            type={type}
                            toggle={() => toggle()}
                          />
                        );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">Nenhum registro encontrado...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
