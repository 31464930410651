import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import { ValueContext } from "views/admin/Home/index";

import api from "config/api";
import errorRequest from "common/errorRequest";
import Loading from "components/Loading";

const noImage = require("assets/img/brand/no-image.png");

function ItemList({ name, type, activeType }) {
  const [nuvemProduct, setNuvemProduct] = useContext(ValueContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState();
  const [tagsList, setTagsList] = useState();

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (activeType > 0 && !nuvemProduct) {
        try {
          const { data } = await api.app.get(`/product?type_id=${activeType}`);
          setProductsList(data);
          setTagsList(false);
        } catch (error) {
          console.log("Sem produtos neste tipo");
        }
      } else if (nuvemProduct) {
        try {
          const { data } = await api.app.get(
            `/product/${nuvemProduct.nuvemShopIdProduct}`
          );
          setTagsList(data.Tag);
        } catch (error) {
          console.log("Sem tags neste produto");
        }
      }
      setLoading(false);
    })();
  }, [activeType, nuvemProduct]);

  async function editProduct(e, id) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await api.app.get(`/nuvem/product/${id}`);
      let product = data;

      if (product.name) {
        const newProduct = {
          name: product.name.pt,
          tags: product.tags,
          urlProduct:
            product.images && product.images.length
              ? product.images[0].src
              : noImage,
          nuvemShopIdProduct: product.id,
          price:
            product.variants && product.variants.length > 0
              ? product.variants[0].promotional_price
                ? product.variants[0].promotional_price
                : product.variants[0].price
              : 0,
        };
        setNuvemProduct(newProduct);
      } else {
        setNuvemProduct(false);
      }
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function deleteProduct(e, id) {
    e.preventDefault();
    setLoading(true);
    try {
      await api.app.delete(`/product/${id}`);
      editProduct(e, id);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function deleteTag(e, id, tag) {
    e.preventDefault();
    setLoading(true);
    let oldTags = nuvemProduct.tags.split(", ");
    oldTags.splice(oldTags.indexOf(tag), 1).join(", ");
    try {
      await api.app.delete(
        `/tag/${id}?prod_id=${
          nuvemProduct.nuvemShopIdProduct
        }&tags=${oldTags.join(", ")}`
      );
      editProduct(e, nuvemProduct.nuvemShopIdProduct);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <>
      {loading && <Loading />}
      <div className="item-list-container mt-4 mt-lg-0">
        <h4>{name}</h4>
        <div className="item-list">
          {productsList && productsList.length > 0 && type === "produtos" ? (
            productsList.map((product) => (
              <span
                key={`${product.name}-${product.nuvemshop_prod_id}`}
                className="item-list-row"
              >
                <span className="item-list-name">
                  {product && product.name}
                </span>
                <div className="item-list-buttons">
                  <span
                    onClick={(e) => editProduct(e, product.nuvemshop_prod_id)}
                    className="item-list-button edit-button"
                  >
                    <i className="fas fa-edit" />
                  </span>
                  <span
                    onClick={(e) => deleteProduct(e, product.nuvemshop_prod_id)}
                    className="item-list-button delete-button"
                  >
                    <i className="fas fa-times" />
                  </span>
                </div>
              </span>
            ))
          ) : tagsList && tagsList.length > 0 && type === "tags" ? (
            tagsList.map((tag) => (
              <span key={`tag-${tag.id}`} id={tag.id} className="item-list-row">
                <span className="item-list-name">{tag.display_name}</span>
                <div className="item-list-buttons">
                  <span
                    className="item-list-button delete-button"
                    onClick={(e) => deleteTag(e, tag.id, tag.name)}
                  >
                    <i className="fas fa-times" />
                  </span>
                </div>
              </span>
            ))
          ) : (
            <span className="item-list-row">
              <span className="item-list-name">
                Não foram encontrad{type === "tags" ? "a" : "o"}s {type}
              </span>
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default ItemList;
