import axios from "axios";

// Troque a baseURL pela rota da API desejada.
const api = {
  authenticate: axios.create({
    baseURL: "https://www.eva-system-api.plataformaeva.com/",
  }),
  app: axios.create({
    baseURL: "https://www.variacoes-personalizadas-api.plataformaeva.com",
  }),
};

const apiKeys = Object.keys(api);

apiKeys.forEach((key) => {
  api[key].interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  });
});

export default api;
